.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.description {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    font-size: 0.85rem;
    max-width: var(--max-width);
    width: 100%;
    z-index: 2;
    font-family: var(--font-mono);
}

.description a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.description p {
    position: relative;
    margin: 0;
    padding: 1rem;
    /* background-color: rgba(var(--callout-rgb), 0.5); */
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius);
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
}

.code {
    font-weight: 700;
    font-family: var(--font-mono);
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(25%, auto));
    width: var(--max-width);
    max-width: 100%;
    text-align: center;
}

.card {
    padding: 1rem 1.2rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
    cursor: pointer;
    text-align: center;
}

.card span {
    display: inline-block;
    transition: transform 200ms;
}

.card h2 {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
}

.card p {
    margin: 0;
    opacity: 0.6;
    font-size: 0.9rem;
    line-height: 1.5;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 4rem 0;
}

.center::after {
    background: var(--primary-glow);
    width: 240px;
    height: 180px;
    z-index: -1;
}

.center::before,
.center::after {
    content: '';
    /* left: 50%; */
    position: absolute;
    filter: blur(45px);
    transform: translateZ(0);
}

.logo {
    position: relative;
}

.card {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
    margin: 1rem 1rem 1rem 1rem;
    width: 350px;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
    .card:hover span {
        transform: translateX(4px);
    }
}

@media (prefers-reduced-motion) {
    .card:hover span {
        transform: none;
    }
}

/* Mobile */
@media (max-width: 700px) {
    .content {
        padding: 4rem;
    }

    .grid {
        grid-template-columns: 1fr;
        margin-bottom: 120px;
        max-width: 320px;
        text-align: center;
    }

    .center {
        padding: 1rem 0;
    }

    .card {
        padding: 1rem 2.5rem;
        margin: 1rem 1rem 0rem 1rem;
    }

    .card h2 {
        margin-bottom: 0.5rem;
    }

    .description {
        font-size: 0.8rem;
    }

    .description a {
        padding: 1rem;
    }

    .description p,
    .description div {
        display: flex;
        justify-content: center;
        position: fixed;
        width: 100%;
    }

    .description p {
        align-items: center;
        inset: 0 0 auto;
        padding: 2rem 1rem 1.4rem;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
        background: linear-gradient(
            to bottom,
            rgba(var(--background-start-rgb), 1),
            rgba(var(--callout-rgb), 0.5)
        );
        background-clip: padding-box;
        backdrop-filter: blur(24px);
    }

    .description div {
        align-items: flex-end;
        pointer-events: none;
        inset: auto 0 0;
        padding: 2rem;
        height: 200px;
        background: linear-gradient(to bottom, transparent 0%, rgb(var(--background-end-rgb)) 40%);
        z-index: 1;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
    /* .grid {
    grid-template-columns: repeat(2, 50%);
  } */
}

@media (max-width: 700px) {
    .card {
        width: 100%;
    }
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.tabs {
    width: 100%;
    padding: 1rem;
}

.dashboard {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;
    align-items: center; */
    padding: 6rem;
    min-height: 100vh;
}

.description {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    font-size: 0.85rem;
    max-width: var(--max-width);
    width: 100%;
    z-index: 2;
    font-family: var(--font-mono);
}

.description a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.description p {
    position: relative;
    margin: 0;
    padding: 1rem;
    /* background-color: rgba(var(--callout-rgb), 0.5); */
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    border-radius: var(--border-radius);
}

:root {
    --max-width: 1100px;
    --border-radius: 12px;
    --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
        'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
        'Courier New', monospace;

    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 40, 44, 52;
    --background-end-rgb: 40, 44, 52;

    --primary-glow: radial-gradient(rgba(73, 234, 203, 0.2), rgba(73, 234, 203, 0));
    --secondary-glow: linear-gradient(
        to bottom right,
        rgba(73, 234, 203, 0),
        rgba(73, 234, 203, 0),
        rgba(73, 234, 203, 0.3)
    );

    --tile-start-rgb: 239, 245, 249;
    --tile-end-rgb: 228, 232, 233;
    --tile-border: conic-gradient(
        #00000080,
        #00000040,
        #00000030,
        #00000020,
        #00000010,
        #00000010,
        #00000080
    );

    --callout-rgb: 238, 240, 241;
    --callout-border-rgb: 172, 175, 176;
    --card-rgb: 180, 185, 188;
    --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;

        --primary-glow: radial-gradient(rgba(73, 234, 203, 0.4), rgba(73, 234, 203, 0));
        --secondary-glow: linear-gradient(
            to bottom right,
            rgba(73, 234, 203, 0),
            rgba(73, 234, 203, 0),
            rgba(73, 234, 203, 0.3)
        );

        --tile-start-rgb: 2, 13, 46;
        --tile-end-rgb: 2, 5, 19;
        --tile-border: conic-gradient(
            #ffffff80,
            #ffffff40,
            #ffffff30,
            #ffffff20,
            #ffffff10,
            #ffffff10,
            #ffffff80
        );

        --callout-rgb: 20, 20, 20;
        --callout-border-rgb: 108, 108, 108;
        --card-rgb: 100, 100, 100;
        --card-border-rgb: 200, 200, 200;
    }
}

@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}

@font-face {
    font-family: 'Inter';
    src: url(../../public/fonts/Inter-VariableFont.ttf);
}

.medium {
    display: flex;
}

.addressTable td:hover {
    cursor: pointer;
    color: var(--mantine-color-brand-0);
}

.address {
    overflow-wrap: break-word;
}

.address a {
    text-decoration: none;
}

.selectedAddress:hover {
    cursor: pointer;
    color: var(--mantine-color-brand-0);
}

@media (max-width: 700px) {
    .medium {
        width: 500px;
        font-size: var(--mantine-font-size-sm);
    }
}

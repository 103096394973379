.medium {
    display: flex;
}

.small {
    display: none;
}

.transaction {
    width: calc(100%);
    overflow-wrap: break-word;
}

@media (max-width: 700px) {
    .medium {
        display: none;
    }

    .small {
        display: flex;
        font-size: var(--mantine-font-size-sm);
    }

    .transaction {
        width: calc(88%);
        overflow-wrap: break-word;
    }
}

@media (max-width: 500px) {
    .medium {
        display: none;
    }

    .small {
        display: flex;
    }

    .transaction {
        width: calc(70%);
        overflow-wrap: break-word;
    }
}

@media (max-width: 400px) {
    .transaction {
        width: calc(52%);
        overflow-wrap: break-word;
    }
}
